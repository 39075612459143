<template>
    <div class="float">
        <pre v-html="$globalData.data.pengumuman1" style="font: 'tahoma'; font-size: 12px;"></pre>
    </div>
</template>

<script>

export default {
    name: 'Info',

    components: {
    },
    mounted() {
        this.waitForData()
            .then(() => {
                console.log('info', this.$globalData.data)
                let data = this.$globalData.data.pengumuman1
                let biaya = this.$globalData.data.konfirmalumni == '1' ? this.$globalData.data.biayapendaftaranalumni + ' (Alumni)' : this.$globalData.data.biayapendaftaran
                let updatedData = data.replace(/!va/g, this.$globalData.data.nova);
                updatedData = updatedData.replace(/!nama/g, this.$globalData.data.nama);
                updatedData = updatedData.replace(/!biaya/g, biaya);
                updatedData = updatedData.replace(/!hari/g, this.$globalData.data.haritest);
                updatedData = updatedData.replace(/!tanggal/g, this.$globalData.data.tanggaltest);
                updatedData = updatedData.replace(/!user/g, this.$globalData.data.userid);
                updatedData = updatedData.replace(/!pass/g, this.$globalData.data.password);
                updatedData = updatedData.replace(/!gel/g, this.$globalData.data.gelombanggeneral);

                this.$globalData.data.pengumuman1 = updatedData
            });
    },
    methods: {
        async waitForData() {
            // Tunggu hingga data terisi
            while (!this.$globalData.data || Object.keys(this.$globalData.data).length === 0) {
                await new Promise(resolve => setTimeout(resolve, 100)); // Delay 100ms
            }
            return true;
        }
    }
}

</script>