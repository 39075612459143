<template>
    <div class="float-container">

        <div class="border-bottom text-left">
            <b>PENDAFTARAN {{
                $globalData.data.jenjang ==
                    'P' ? 'SMP' :
                    'SMA' }}{{ ' Gel ' +
                    $globalData.data.gelombanggeneral }}</b> <br />{{ $globalData.data.nama }}
        </div>
        <!-- < h2> Menu Driver { { $globalData.data.gelombang }}</h2> -->
        <div class="col-md-7 p-0 m-0">
            <Profile v-show="$globalData.SelectedMenu == '0'" />
            <Ortu v-show="$globalData.SelectedMenu == '1'" />
            <Info v-show="$globalData.SelectedMenu == '2'" />
            <Upload v-show="$globalData.SelectedMenu == '3'" />
            <Kartu v-show="$globalData.SelectedMenu == '4'" />
        </div>
    </div>

</template>

<script>
import Profile from '../views/profile'
import Info from '../views/info'
import Upload from '../views/upload'
import Kartu from '../views/kartu'
import Ortu from '../views/ortu'
import axios from "axios";
export default {
    name: 'Menu',

    components: {
        Profile, Info, Upload, Kartu, Ortu
    },
    data() {
        return {
            para: this.$route.params.id,
        }
    },
    // mounted() {
    mounted: async function () {
        const isAuthenticated = localStorage.getItem('authToken');
        if (this.para != isAuthenticated) {
            this.$router.push({ path: "/" })
            console.log('NOtvalid')
        } else {
            this.$globalData.TokenOK = 1
            //  $globalData.UserId
            this.$globalData.UserId = localStorage.getItem('userid')
            // let tkn = localStorage.getItem('psbtoken')
            console.log("userid", this.$globalData.UserId)
        }
        console.log("data", this.$globalData.data)
        if (Object.keys(this.$globalData.data).length === 0) {
            // alert("Objek data kosong");
            await axios
                .get(this.$appUrl + "getdata.php?login=" + this.$globalData.UserId + "&token=" + localStorage.getItem('psbtoken'))
                .then((res) => {
                    this.$globalData.data = res.data[0];
                    this.$globalData.databackup = JSON.parse(JSON.stringify(res.data[0]));
                    if (!this.$globalData.data.warganegara) {
                        this.$globalData.data.warganegara = 'wni';
                    }
                    if (!this.$globalData.data.pasfoto) {
                        this.$globalData.data.pasfoto = 'user.png';
                    }
                })
            console.log("finished data")
        }
    }
}
</script>
