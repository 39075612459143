<template>
   <div class=" d-flex flex-column vh-100 p-0 text-center col-md-6">
      <!-- Div Atas dengan Background Biru -->
      <div class="bg-success " style="height: 40%;"></div>

      <!-- Div Bawah dengan Background Putih -->
      <div class="bg-white" style="height: 10px;"></div>

      <div class="container text-center" style="position:absolute">
         <br />
         <br />
         <div class="text-white text-center">
            <p style="font-size: 26px;">Ponpes BAITURRAHMAN</p>
            <p style="font-style: italic;">Aplikasi PSB 2024</p>
         </div>
         <br />
         <!-- <br /> -->
         <div class="container">
            <b-card class="container px-0 mt-4 mb-4 mr-4 shadow" header="Login Page">
               <div>
                  <b-form>
                     <b-input-group size="sm">
                        <b-form-input v-model="$globalData.UserId" placeholder="User id" />
                        <b-input-group-append is-text>
                           <b-icon-person-check />
                        </b-input-group-append>
                     </b-input-group>
                     <b-input-group size="sm" class="mt-2">
                        <b-form-input v-model="password" :type="passwordtype ? 'password' : 'text'"
                           placeholder="password" />
                        <b-input-group-append is-text>
                           <b-icon-key @click="passwordtype = !passwordtype" />
                        </b-input-group-append>
                     </b-input-group>
                  </b-form>
                  <b-button class="mt-3 col-6" block @click="gologin"><b-icon-arrow-right />Login</b-button>
               </div>
               <div class="row text-center">
                  <span class="ml-3 mt-2" style="font-size: 12px;">Jika belum memiliki user, register
                     <b-link @click="showModal">disini</b-link></span>
               </div>
            </b-card>
         </div>
         <span style="font-size: 13px;">Contact kami di 0821-1638-6662</span>
      </div>
      <!-- <img :src="$appUrl + 'img/psb.jpg'" /> -->
      <!-- </div> -->
      <b-modal ref="my-modal" hide-footer hide-header size="sm" class="container">
         <h4 class="text-dark text-shadow text-center">Registrasi Akun</h4>
         <div class="text-center">
            <img :src="$appUrl + 'img/psb.jpg'" width="40%" />
         </div>
         <b-form @submit="onSubmit" class="container">
            <b-form-radio-group v-model="form.jenjang" class="mb-2 text-center mt-4"
               :options="[{ text: 'SMP', value: 'P' }, { text: 'SMA', value: 'A' }]" required></b-form-radio-group>
            <b-input-group size="sm" class="mb-2 mt-3">
               <b-input-group-prepend is-text>
                  <b-icon-person />
               </b-input-group-prepend>
               <b-form-input v-model="form.nama" placeholder="nama lengkap*" required />
            </b-input-group>
            <b-input-group size="sm" class="mb-2">
               <b-input-group-prepend is-text>
                  <b-icon-whatsapp />
               </b-input-group-prepend>
               <b-form-input v-model="form.nohp" placeholder="No.HP/WA(62...)*" required @input="ensureCountryCode" />
            </b-input-group>
            <b-input-group size="sm" class="mb-2">
               <b-input-group-prepend is-text>
                  <b-icon-envelope />
               </b-input-group-prepend>
               <b-form-input v-model="form.email" placeholder="email" />
            </b-input-group>
            <b-input-group size="sm" class="mb-2">
               <b-input-group-prepend is-text>
                  <b-icon-person-check />
               </b-input-group-prepend>
               <b-form-input v-model="form.user" placeholder="nama user*" required @input="removeSpaces" />
            </b-input-group>
            <b-input-group size="sm" class="mb-2">
               <b-input-group-prepend is-text>
                  <b-icon-key />
               </b-input-group-prepend>
               <b-form-input v-model="form.pass" placeholder="Passsword*" required />
            </b-input-group>
            <hr />
            <b-button size="sm" type="submit">Register</b-button>
         </b-form>
      </b-modal>
      <b-modal id="modal-confirm" title="Konfirmasi" size="sm" hide-footer>
         <p>Register dengan data:</p>
         <pre>
   Jenjang : {{ form.jenjang == 'P' ? 'SMP' : 'SMA' }}   
   Nama    : {{ form.nama }}
   No.WA   : {{ form.nohp }}
   User    : {{ form.user }}
   Pass    : {{ form.pass }}
   Email   : {{ form.email }}
        
   No.WA yang dimasukkan silahkan di 
   cek Ulang karena kami akan mengirimkan 
   notif via no WA.
         </pre>

         <b-button size="sm" @click="konfirm">OK Confirm</b-button>
      </b-modal>
   </div>
</template>

<script>
import axios from "axios";

export default {
   name: "Home",
   components: {
      //  Navbar,
   },
   data() {
      return {
         password: "",
         ketlogin: "",
         passwordtype: true,
         bgurl: this.$appUrl + "bg.jpg",
         form: {
            nama: '',
            nohp: '',
            user: '',
            pass: '',
            jenjang: 'P',
            email: ''
         }
      };
   },
   methods: {
      ensureCountryCode() {
         let nohp = this.form.nohp.replace(/^0/, ""); // Hapus 0 di awal
         if (!nohp.startsWith("62")) {
            nohp = "62" + nohp; // Tambahkan awalan 62 jika belum ada
         }
         this.form.nohp = nohp.replace(/^62(0+)/, "62"); // Hapus semua 0 setelah 62
      },
      showModal() {
         this.$refs['my-modal'].show()
      },
      onSubmit(event) {
         event.preventDefault();
         // alert(JSON.stringify(this.form))
         axios
            .get(this.$appUrl + "cekuser.php?user=" + this.form.user)
            .then((res) => {
               console.log(res.data.length)
               if (res.data.length > 0) {
                  // alert('nama user sudah ada')
                  this.$toast.error("FAIL, Nama User sudah ada .....", {
                     variant: "danger",
                     position: "top-right",
                     duration: 3000,
                     dismissible: true,
                  });
               } else {
                  this.$bvModal.show('modal-confirm')
               }
            })



         // const message = `Assalamualaikum wr.wb. \nSaya telah melakukan registrasi di https://newpsb.baiturrahman.com dengan user: ${this.form.user} password:${this.form.pass} an. ${this.form.nama} noWA terdaftar ${this.form.nohp}`;
         // window.open(`https://api.whatsapp.com/send/?phone=628151633007&text=${encodeURIComponent(message)}&type=phone_number&app_absent=0`)

      },

      konfirm() {
         axios
            .post(this.$appUrl + "register.php", this.form)
            .then((res) => {
               console.log("register", res.data);
               let data = {
                  userkey: "f968980bd613",
                  passkey: "cd539687a0b2946224c50b2e",
                  to: this.form.nohp,
                  nama: this.form.nama,
                  jenjang: this.form.jenjang,
                  user: this.form.user,
                  pass: this.form.pass,

                  message: "Terima kasih sudah Registrasi di \nPSB Baiturrahman\n( https://newpsb.baiturrahman.com )\n\nAtas Nama: " + this.form.nama + "\nUser: " + this.form.user + "\nPassword: " + this.form.pass + "\n\nTerima kasih"
               }
               console.log(data);
               axios
                  // .post(this.$appUrl + "kirimwa.php", data)
                  .post(this.$appUrl + "saungwa.php", data, { timeout: 9000 })
                  .then((res) => {
                     console.log("response wa", res.data)
                     const resData = res.data;
                     if (resData.error) {
                        // Jika terdapat properti error
                        console.log('API Error:', resData.error);
                     } else {
                        // Jika respons berhasil
                        if (resData.success == false) {
                           console.log('Validation Number Error :', resData);
                        } else {
                           console.log('Message sent successfully:', resData);
                        }
                     }
                  })
                  .catch(error => {
                     console.log('errr code wa', error)
                     if (error.code === 'ECONNABORTED') {
                        console.error('Request timed out');
                     } else {
                        console.error('Error:', error.message);
                     }
                  });
               this.$bvModal.hide('modal-confirm')
               this.$refs['my-modal'].hide()
               this.$globalData.UserId = this.form.user
               this.password = this.form.pass
            })


      },
      removeSpaces() {
         this.form.user = this.form.user.replace(/\s/g, ''); // Menghapus semua spasi
      },
      cekuser() {
         axios
            .get(this.$appUrl + "cekuser.php?user=" + this.form.user)
            .then((res) => {
               if (res) {
                  alert('nama user sudah ada')
               }
            })

      },
      gologin() {
         axios
            .post(this.$appUrl + "login.php", {
               username: this.$globalData.UserId,
               password: this.password,
            })
            .then((res) => {
               if (res.data.length > 0) {
                  this.$globalData.TokenOK = 1;

                  localStorage.setItem('userid', this.$globalData.UserId)
                  localStorage.setItem('psbtoken', res.data[0].token1);
                  // let kode = Date.now() + Math.floor(Math.random() * 1000);
                  let kode = res.data[0].token1;
                  localStorage.setItem('authToken', res.data[0].token1);
                  this.$router.push({ path: "/menu/" + kode });
                  console.log("data user", res.data[0])
                  this.$globalData.data = res.data[0];
                  if (!this.$globalData.data.warganegara) {
                     this.$globalData.data.warganegara = 'wni';
                  }
                  if (!this.$globalData.data.pasfoto) {
                     this.$globalData.data.pasfoto = 'user.png';
                  }

                  this.$globalData.databackup = JSON.parse(JSON.stringify(res.data[0]));
                  // this.$router.push({ path: "/inward" });
               } else {
                  this.$globalData.TokenOK = 0;
                  this.$bvModal.msgBoxOk("User & Password tidak sesuai ...", {
                     centered: true,
                  });
               }
            });
      },
   },
};
</script>

<style>
.flex-fill {
   height: 50%;
}

.text-shadow {
   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
